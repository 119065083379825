import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <body className="App-body">
        <h2>Julius de Weerth</h2>
        <h4>
          Software and Hardware Engineer <br></br>based in Berlin
        </h4>
        <br></br>
        <p>Hi there!</p>
        <p>I build physical and digital Prototypes.</p>
        <p>Sometimes they turn into products.</p>
        <br></br>
        <p>
          {" "}
          <b> hello </b>(at) jdew.de
        </p>
      </body>
    </div>
  );
}

export default App;
